<template>
  <v-card
    v-if="data">
    <v-col
      class="count-stock-warehouses rounded pa-5"
      cols="12">
      <div class="d-flex justify-space-between">
        <div>
          <h1> นับของ </h1>
          <table class="mt-2">
            <tr>
              <th class="text-left px-4">
                Warehouse :
              </th>
              <td>{{ data.warehouse.name }}</td>
            </tr>
            <tr>
              <th class="text-left px-4">
                Note :
              </th>
              <td>{{ data.note }}</td>
            </tr>
            <tr>
              <th class="text-left px-4">
                Completed By :
              </th>
              <td v-if="data.prepareConfirmBy && data.state === 'prepare_to_confirm'">
                <span> {{ data.prepareConfirmBy.email }} </span>
              </td>
            </tr>
            <tr>
              <th class="text-left px-4">
                Completed Date :
              </th>
              <td>
                <span> {{ data.prepareConfirmDate | dateTimeFormat() }} </span>
              </td>
            </tr>
            <tr>
              <th class="text-left px-4">
                Approved By :
              </th>
              <td v-if="data.completedBy && data.state === 'completed'">
                <span> {{ data.completedBy.email }} </span>
              </td>
            </tr>
            <tr style="border-bottom: 1px solid #cfcfcf;">
              <th class="text-left px-4">
                Approved Date :
              </th>
              <td>
                <span> {{ data.completedDate | dateTimeFormat() }} </span>
              </td>
            </tr>
          </table>
        </div>
        <div>
          <h1 v-if="data.state !== 'pending'">
            {{ sumCount }} / {{ sumStock }}
          </h1>
        </div>
        <div>
          <h1 :style="{ color: mapStateColor(data.state) }">
            {{ mapStateText(data.state) }}
          </h1>
        </div>
      </div>
    </v-col>
    <v-col
      class="count-stock-warehouses rounded pa-5 mt-5"
      cols="12">
      <v-data-table
        :headers="headers"
        :items="countStockDetails"
        :items-per-page="-1"
        class="elevation-1"
        :loading="loading"
        dense
        hide-default-footer>
        <template #[`item.createdAt`]="{ item }">
          {{ item.createdAt | dateTimeFormat() }}
        </template>
        <template #[`item.countProduct`]="{ item }">
          <span>
            {{ countProduct(item) | showNumberFormat() }}
          </span>
        </template>
        <template #[`item.options`]="{ item }">
          <v-menu offset-y>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                link
                @click="removeCountStockDetail(item)">
                <span>
                  ลบ
                </span>
              </v-list-item>
              <v-list-item
                link
                @click="exportCsv(item)">
                <span>
                  Export CSV
                </span>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-col>
  </v-card>
</template>

<script>
import CountStockProvider from '@/resources/CountStockProvider'
import { ExportToCsv } from 'export-to-csv'

const CountStockService = new CountStockProvider()

export default {
  data () {
    return {
      loading: false,
      data: null,
      states: {
        pending: {
          text: 'รอนับ',
          color: '#4caf50',
          value: 'pending'
        },
        prepare_to_confirm: {
          text: 'รอตรวจสอบ',
          color: '#fb9801',
          value: 'prepare_to_confirm'
        },
        completed: {
          text: 'ยืนยันแล้ว',
          color: '#7B1FA2',
          value: 'completed'
        },
        canceled: {
          text: 'ยกเลิก',
          color: '#616161',
          value: 'canceled'
        }
      },
      headers: [
        {
          text: 'Create Time',
          value: 'createdAt',
          align: 'center'
        },
        {
          text: 'Location',
          value: 'location',
          align: 'center'
        },
        {
          text: 'Count Product',
          value: 'countProduct',
          align: 'center',
          sortable: false
        },
        {
          text: 'Count By',
          value: 'createdBy.email',
          align: 'center',
          sortable: false
        },
        {
          text: 'Options',
          value: 'options',
          align: 'center',
          sortable: false
        }
      ],
      countStockDetails: []
    }
  },
  computed: {
    id () {
      return this.$route.params.id || null
    },
    sumCount () {
      return this.data?.products.reduce((ps, p) => ps + p.skus.reduce((ss, s) => s.count + ss, 0), 0) || 0
    },
    sumStock () {
      return this.data?.products.reduce((ps, p) => ps + p.skus.reduce((ss, s) => s.stock + ss, 0), 0) || 0
    }
  },
  async created () {
    await this.fetchData()
    await this.fetchCountStockDetail()
  },
  methods: {
    async removeCountStockDetail (item) {
      this.$store.dispatch('Components/setModal', {
        value: true,
        title: 'Remove',
        message: 'Do you want to remove.',
        confirmText: 'Remove',
        confirmType: 'error',
        cancelType: '',
        cancelText: 'Cancel',
        onConfirm: () => this.confirmRemoveCountStockDetail(item.id)
      })
    },
    async confirmRemoveCountStockDetail (id) {
      try {
        this.loading = true
        const data = await CountStockService.removeCountStockDetail(id)
        if (data) {
          this.fetchCountStockDetail()
          this.$store.dispatch('Components/setSnackbar', {
            value: true,
            message: 'สำเร็จ',
            type: 'success'
          })
        }
      } catch (error) {
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: `[error on fetch data]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    mapStateColor (state) {
      return this.states[state]?.color || '#000000'
    },
    mapStateText (state) {
      return this.states[state]?.text || state
    },
    countProduct (item) {
      return item.products.reduce((ps, p) => ps + p.skus.reduce((ss, s) => s.count + ss, 0), 0) || 0
    },
    exportCsv (item) {
      const options = {
        filename: `${this.data.id}_detail_${item.location}`,
        showLabels: false,
        useKeysAsHeaders: true
      }

      const data = []

      item.products.forEach((product) => {
        product.skus.forEach((sku) => {
          data.push({
            name: product.name,
            model: product.model,
            code: sku.code,
            color: sku.color,
            size: sku.size,
            price: sku.price,
            count: sku.count
          })
        })
      })

      const csvExporter = new ExportToCsv(options)
      csvExporter.generateCsv(data)
    },
    async fetchData () {
      try {
        this.loading = true
        const { data } = await CountStockService.getById(this.id)
        this.data = data
      } catch (error) {
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: `[error on fetch data]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async fetchCountStockDetail () {
      try {
        this.loading = true
        const { data } = await CountStockService.getCountStockDetails({
          countStock: this.id
        })
        this.countStockDetails = data || []
      } catch (error) {
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: `[error on fetch data]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.cl-red {
  color: #fc7a7a;
}
.less-stock {
  background-color: #fc7a7a;
}
.grater-stock {
  background-color: #7ae0fc;
}
.count-stock-warehouses {
  width: 100%;
}
.product-header {
  width: 100%;
  padding: 0 10px;
  background-color: #f3f3f3;
  height: 140px;
  display: flex;
  align-items: center;
}
.product-img-wrapper {
  padding: 2px;
  width: 80px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #ababab;
}
.product-list {
  padding: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 5px;
}
</style>
